import { getLinkType, NormalizedLink } from '~/components/normalized-link.tsx'
import { Icon } from '~/components/icon.tsx'

export function ExternalIconWrapper({
  external,
  title,
}: {
  external?: boolean
  title: string
}) {
  if (external) {
    return (
      <>
        <span className="mr-1">{title}</span>
        <Icon className="h-4 w-4 text-icon-muted" name="open-in" />
      </>
    )
  }
  return <>{title}</>
}

export function mapStrapiLinkGroups(groups: (FooterLinkGroup | null)[]) {
  return groups.flatMap(group =>
    group
      ? [
          {
            title: group.title,
            links: mapStrapiLinks(group.links),
          },
        ]
      : [],
  )
}

export function mapStrapiLinks(links: (FooterLink | null)[]) {
  return links.flatMap(link =>
    link
      ? [
          <NormalizedLink to={link.url} key={link.title}>
            <ExternalIconWrapper
              external={getLinkType(link.url) === 'external'}
              title={link.title}
            />
          </NormalizedLink>,
        ]
      : [],
  )
}

export type FooterLinkGroup = {
  title: string
  links: (FooterLink | null)[]
}

export type FooterLink = {
  title: string
  url: string
}
