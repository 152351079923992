import { useMatches } from '@remix-run/react'
import { useEffect, useMemo } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { trackAbTest } from '~/utils/gtm.client.ts'

export function makeTitle(title: string | undefined) {
  return `${title} - Account portal`
}

export function useMatchesData<T>(id: string): T | unknown {
  const matchingRoutes = useMatches()
  const route = useMemo(
    () => matchingRoutes.find(route => route.id === id),
    [matchingRoutes, id],
  )
  return route?.data
}

export type Experiment = {
  experiment: string
  variant: string
}

export type LoaderDataWithExperiments = {
  experiments?: Experiment[]
}

const isLoaderDataWithExperiment = (
  data: unknown,
): data is LoaderDataWithExperiments => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'experiments' in data &&
    Array.isArray(data.experiments)
  )
}

export function useTrackExperimentsData() {
  const matches = useMatches()

  const experimentsData = useMemo(() => {
    return matches
      .map(value => {
        if (isLoaderDataWithExperiment(value.data)) {
          return value.data.experiments
        }
        return null
      })
      .filter(experiments => !!experiments)
      .reduce((a, b) => {
        return a?.concat(b || []) || []
      }, [])
  }, [matches])

  useEffect(() => {
    if (experimentsData) {
      experimentsData.forEach(item => {
        if (!item) return
        datadogRum.addFeatureFlagEvaluation(item.experiment, item.variant)
        trackAbTest(item.experiment, item.variant)
      })
    }
  }, [experimentsData])
}
