import { useTranslation } from 'react-i18next'
import type { ReactNode } from 'react'
import { Footer } from '@rouvydev/web-components/basics'
import { useUnwrappedRootLoaderData } from '~/root.tsx'
import { mapStrapiLinkGroups, mapStrapiLinks } from '~/utils/footer-links.tsx'
import { Navbar } from '~/components/navbar.tsx'
import type { NavbarSettingsFragment } from '~/model/api.ts'
import { cn } from '@rouvydev/web-components/utils'

export type Props = {
  children: ReactNode
  navbarSettings?: NavbarSettingsFragment
}

export function Layout({ children, navbarSettings }: Props) {
  const { t } = useTranslation()
  const { footer, navbarSettings: defaultNavbarSettings } =
    useUnwrappedRootLoaderData()
  navbarSettings = navbarSettings ?? defaultNavbarSettings

  return (
    <div className="h-full">
      <div className="relative flex h-full flex-col">
        <Navbar navbarSettings={navbarSettings} />
        <div
          className={cn(
            'relative flex-1',
            navbarSettings?.fixed === true ||
              navbarSettings?.transparent === true
              ? 'max-md:pt-20'
              : '',
          )}
        >
          {children}
        </div>
        {footer && (
          <div className="border-t border-border-secondary-subtle">
            <Footer
              menuLinks={mapStrapiLinkGroups(footer.groups)}
              footLinks={mapStrapiLinks(footer.bottomLinks)}
              rights={t('footer_rights')}
            />
          </div>
        )}
      </div>
    </div>
  )
}
